#__next {
  min-height: 100vh !important;
  display: flex;
  justify-content: center;
}
.chakra-pin-input {
  border: 2px solid #d6dad5 !important;
}
.chakra-pin-input:focus {
  border: 2px solid $color-primary !important;
}
.button {
  &-header {
    width: 100%;
    background: white;
    color: black;
    font-size: 1.2rem !important;
    border: 1px solid black;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-weight: 40;
    padding: 5px 5px;
    line-height: 26px;
    margin-right: 0.25rem;
    font-weight: 500;
  }
  &-header:last-child {
    margin-right: 0;
  }
  &-header:focus {
    box-shadow: none !important;
  }
  &-header_active {
    background: black;
    color: white;
    font-size: 1.2rem !important;
  }
}
.store {
  &-open {
    background-color: #e55353;
    border: 1px solid #f1431c;
    color: #fff;
  }
  &-closed {
    background-color: #2eb85c;
    border: 1px solid #19b54b;
    box-shadow: 0 0 0 0 rgba(46, 184, 92, 1);
    animation: button 1s infinite;
    color: #fff;
  }
}
.css-1pjmkzr {
  background-color: rgba(0, 0, 0, 0) !important;
}
.paginator {
  .chakra-stack {
    .chakra-button {
      width: 40px;
      height: 40px;
      border-radius: 2px;
      font-size: 1.2rem;
      font-weight: 400;
      color: #22252f;
      &[aria-current='true'] {
        color: white;
        background: black !important;
      }
    }
  }
}

.card {
  background: #FFF;
  border-radius: 4px;
  border-color: rgba(#000000,  0.1) !important;
  width: 100%;
  .card-header {
    padding: 1rem;
    border-bottom: 1px solid rgba(#000000, 0.1);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .card-body {
    padding: 1rem;
  }
  .card-footer {
    border-top: 1px solid rgba(#000000,0.1);
    padding: 1rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.chakra-input__group {
  .chakra-select {
    padding-left: 40px;
  }
  textarea {
    border: 1px solid #E2E8F0
  }
  &:focus-within {
    svg {
      color: #3182ce;
    }
  }
}

.hidden-scrollbar {
  &::-webkit-scrollbar {
    display: none
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    background: #c9c9c9;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #000;
    border: 4px solid #000;
    border-radius: 50px;
  }
}

@keyframes button {
  0% {
    box-shadow: 0 0 0 0 rgba(46, 184, 92, 0.8);
    z-index: 2;
  }

  70% {
    box-shadow: 0 0 0 20px rgba(46, 184, 92, 0);
    z-index: 2;
  }

  100% {
    box-shadow: 0 0 0 0 rgba(46, 184, 92, 0);
    z-index: 2;
  }
}

@keyframes saveButton {
  0% {
    box-shadow: 0 0 0 0 #25D366;
    z-index: 2;
  }

  70% {
    box-shadow: 0 0 0 15px rgba(46, 184, 92, 0);
    z-index: 2;
  }

  100% {
    box-shadow: 0 0 0 0 rgba(46, 184, 92, 0);
    z-index: 2;
  }
}

@keyframes notifyButton {
  0% {
    box-shadow: 0 0 0 0 #1DA1F2;
    z-index: 2;
  }

  70% {
    box-shadow: 0 0 0 15px rgba(46, 184, 92, 0);
    z-index: 2;
  }

  100% {
    box-shadow: 0 0 0 0 rgba(46, 184, 92, 0);
    z-index: 2;
  }
}


.rhap_container {
  max-width: 32vw;
}
.rhap_progress-section {
  display: none !important;
}
