.login {
  width: 100%;
  padding: 0 15px;
  justify-content: center;
  align-items: center;

  &-container {
    justify-content: space-between;
    flex: 1;
    border-radius: 4;
    overflow: hidden;
    box-shadow: 0 5px 11px 0 rgb(60 75 100 / 14%), 0 2px 1px -1px rgb(60 75 100 / 12%),
    0 1px 3px 0 rgb(60 75 100 / 20%);
    }

    &-pin {
      flex: 1;
      padding: 40px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      background-color: white
    }

    &-side {
      flex:1 ;
      background: $color-primary;
      border-left: 20px solid $color-primary-light;
      justify-content: center;
      align-items: center;
      padding: 4rem 0;
      max-height: 380px;
    }

    .button_login {
      background: $color-primary;
      height: 40px;
      font-weight: 400;
      color: #875A0A;
      font-size: 1rem;
      border-radius:0.25rem;
      padding: 0 1.5rem;
      box-shadow: 0 1px 1px 0 rgb(60 75 100 / 14%), 0 2px 1px -1px rgb(60 75 100 / 12%),
0 1px 3px 0 rgb(60 75 100 / 20%);

      &:hover {
        background: $color-primary-light;
        color: white
                        
      }
    }
}
