.products {
  &-title {
    color: #000000;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
  }

  &-category {
    color: #3c4b64;
    font-size: 28px;
    font-weight: bold;
  }
}
