.order-card {
  // border-radius: 0.25rem;
  overflow: hidden;
  background: #ffffff;
  // border: 1px solid #d8dbe0;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__button {
    width: 100%;
    padding: 8px 12px;
    position: relative;
  }

  // &__saleid {
  //   // color: white;
  //   font-size: 0.875rem;
  //   font-weight: 700;
  // }

  // &__info {
  //   display: flex;
  //   justify-content: space-between;
  // }

  // &__title {
  //   display: flex;
  //   align-items: center;
  //   padding: 0 2px 0 8px;
  //   font-size: 1.3125rem;
  //   color: white;
  //   font-weight: 500;
  //   word-break: break-word;
  //   text-align: left;
  //   line-height: 1.2;
  //   margin-top: 13px;
  //   text-transform: uppercase;
  //   // width: 55%;
  // }

  // &__icons {
  //   display: flex;
  //   margin-right: 2px;
  // }

  &__observation {
    font-size: 12px;
    text-align: left;
    font-weight: 500;
    padding: 0 8px;
  }

  // &__progress {
  //   background: rgba(0, 0, 0, 0.3);
  // }

  &__lacto {
    position: absolute;
    left: -50px;
    top: 50%;
    transform: rotate(-20deg);
    width: 130%;
    height: 40px;
    background: #ff7600;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.03rem;
    padding-left: 0.5rem;
    z-index: 49;
    opacity: 0.7;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    pointer-events: none;
  }

  &__waiting {
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1000;
    text-align: center;
    border-radius: 0.25rem;

    & span {
      font-size: x-large;
      color: white;
      text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000,
        -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    }
  }

  &__finished {
    position: relative;
  }
}

.order-product {
  &__items {
    margin: 0 10px;
    display: flex;
    align-items: flex-start;
  }

  &__quantity {
    padding: 0.4em;
    font-size: 80%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: #000;
    margin: 16px 6px 6px 0;
  }

  &__title {
    font-size: 1.3125rem;
    font-weight: 500;
    line-height: 1.2;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    ul li {
      font-size: 13px;
      font-weight: 400;
      list-style: none;
      margin: 3px 3px 3px 0;
    }
  }

  &__total {
    border-top: 1px solid #d8dbe0;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: bold;
    position: relative;
  }
}

.quantity {
  &_secondary {
    background-color: #ced2d8;
  }

  &_success {
    color: white;
    background-color: $color-success;
  }

  &_info {
    color: white;
    background-color: $color-info;
  }

  &_primary {
    background-color: $color-primary;
  }

  &_warning {
    background-color: $color-warning;
  }

  &_danger {
    color: #fff;
    background-color: $color-danger;
  }

  &_dark {
    color: #fff;
    background-color: $color-dark;
  }
}

// .order-cardheader {
//   &__danger {
//     background: $color-danger;
//     color: #ffffff;
//   }

//   &__warning {
//     background: $color-warning;
//     color: #000000;
//   }

//   &__success {
//     background: $color-success;
//     color: #ffffff;
//   }

//   &__dark {
//     background: $color-dark;
//   }

//   &__info {
//     background: $color-info;
//     color: white;
//   }

//   &__light {
//     background: orange;
//   }
// }

.order-finished {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-platinum,
.card-unlimited {
  border: 1px solid #757376;
}
.card-top,
.card-black {
  border: 1px solid #121212;
}
.card-yellow {
  border: 1px solid #efa220;
}
.order-cardtype {
  height: 24px;
  padding: 0px 6px;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
}
.cardtype-platinum {
  border: 1px solid #757376;
  background-color: #e2dce4;
  color: #757376;
}
.cardtype-unlimited {
  border: 1px solid #757376;
  background-color: #ababab;
  color: #fff;
}
.cardtype-top {
  border: 1px solid #121212;
  background-color: #fff;
  color: #121212;
}
.cardtype-black {
  border: 1px solid #121212;
  background-color: #121212;
  color: #fff;
}
.cardtype-yellow {
  border: 1px solid #efa220;
  background-color: #ffba23;
  color: #986000;
}

.names {
  font-size: 0.875rem !important;
  margin-left: 0.5rem;
}
